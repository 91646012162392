import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { DialogClimaComponent } from "./dialog-clima/dialog-clima.component";

import { ImagesService } from "app/services/helper/images.service";
import { RankingsService } from "app/services/rankings/rankings.service";
import { BadgesService } from "../../services/badges/badges.service";
import { PostsService } from "../../services/posts/posts.service";
import { ProjectService } from "../../services/project/project.service";
import { UserService } from "../../services/user/user.service";

import { Project } from "app/shared/models/project.interface";
import { Badge } from "../../shared/models/badge.interface";
import { Post } from "../../shared/models/posts.interface";
import { ProfileUser, User } from "../../shared/models/user.interface";

import { ActivatedRoute, Router } from "@angular/router";
import { feedAnimations } from "app/animations";
import { AuthService } from "app/services/auth/auth.service";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { PersonService } from "app/services/person/person.service";
import { driver } from "driver.js";
import moment from "moment";
import { Subscription } from "rxjs";
import { DialogDarBadgeComponent } from "../../../app/components/dialog-dar-badge/dialog-dar-badge.component";

@Component({
	selector: "app-inicio",
	templateUrl: "./inicio.component.html",
	styleUrls: ["./inicio.component.scss"],
	animations: [feedAnimations],
})
export class InicioComponent implements OnInit {
	TANDEM_PROJECT_ID = this.projectSvc.TANDEM_PROJECT_ID;

	users: Array<any> = [];
	usersRecibidas: Array<any> = [];
	usersEntregadas: Array<any> = [];
	fechaActual = new Date();

	user: User;
	profileUser: ProfileUser;
	pageTimeline: number;
	badgesTimeline: Array<Badge>;
	hasMoreTimeline: boolean;
	pageRecentNews: number;
	recentNews: Array<Post>;
	hasMoreRecentNews: boolean;
	pageRecentNewsFun: number;
	recentNewsFun: Array<Post>;
	hasMoreRecentNewsFun: boolean;
	recentNewsLoadedFun: boolean = false;

	project: Project;
	topByCompetency: any;
	selectedCompetencia: any;
	maxPointCompetenciAux = 0;
	monthIndex: number = new Date().getMonth() + 1;
	yearIndex: number = new Date().getFullYear();

	private updateBadgeSubscription: Subscription;

	recentNewsLoaded: boolean = false;
	badgesTimelineLoaded: boolean = false;
	competenciesLoaded: boolean = false;
	idsBadges: Array<Number>;

	projectId: number;

	evaluation;
	evaluationPeriod;
	canDo = false;

	badgesTandem = 0;
	constructor(
		public dialog: MatDialog,
		private userSvc: UserService,
		private badgesSvc: BadgesService,
		private postsSvc: PostsService,
		private route: ActivatedRoute,
		private projectSvc: ProjectService,
		private rankSvc: RankingsService,
		private imagesSvc: ImagesService,
		private personSvc: PersonService,
		private router: Router,
		private evaluationSvc: EvaluationService,
		private authSvc: AuthService,
	) {
		this.profileUser = {
			totalNumBadgeReceived: 0,
			totalNumBadgeSended: 0,
			totalMonedas: 0,
			totalRankingBronze: 0,
			totalRankingSilver: 0,
			totalRankingGold: 0,
			climaToday: 0,
			totalPublicaciones: 0,
			delegacion: "",
			persona: null,
		};
		this.idsBadges = [];
		this.pageTimeline = 0;
		this.badgesTimeline = [];
		this.pageRecentNews = 0;
		this.recentNews = [];

		this.pageRecentNewsFun = 0;
		this.recentNewsFun = [];
		this.updateBadgeSubscription = badgesSvc.getUpdate().subscribe((message) => {
			this.loadTimeline(true);
			this.userSvc.profile().subscribe((data: any) => {
				this.profileUser = data;
			});
		});
	}
	permisosMedallas = { r: 0, w: 0, d: 0 };
	permisosPublicaciones = { r: 0, w: 0, d: 0 };
	permisosMedallasCL = { r: 0, w: 0, d: 0 };
	permisosPublicacionesCL = { r: 0, w: 0, d: 0 };
	permisosClima = { r: 0, w: 0, d: 0 };
	permisosSIED = { r: 0, w: 0, d: 0 };
	permisosPoll = { r: 0, w: 0, d: 0 };
	permisosTienda = { r: 0, w: 0, d: 0 };
	ngOnInit(): void {
		this.authSvc.getPermisosWeb("WEB-PUBLICACIONES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosPublicaciones.r = data.r;
				this.permisosPublicaciones.w = data.w;
				this.permisosPublicaciones.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-MEDALLAS").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosMedallas.r = data.r;
				this.permisosMedallas.w = data.w;
				this.permisosMedallas.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-CLIMA").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosClima.r = data.r;
				this.permisosClima.w = data.w;
				this.permisosClima.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-TIENDA").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosTienda.r = data.r;
				this.permisosTienda.w = data.w;
				this.permisosTienda.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-MEDALLAS-COMENTARIOSLIKES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosMedallasCL.r = data.r;
				this.permisosMedallasCL.w = data.w;
				this.permisosMedallasCL.d = data.d;
			}
		});
		this.authSvc.getPermisosWeb("WEB-PUBLICACIONES-COMENTARIOSLIKES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosPublicacionesCL.r = data.r;
				this.permisosPublicacionesCL.w = data.w;
				this.permisosPublicacionesCL.d = data.d;
			}
		});

		this.authSvc.getPermisosWeb("WEB-EVALUACIONES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosSIED.r = data.r;
				this.permisosSIED.w = data.w;
				this.permisosSIED.d = data.d;
			}
		});

		this.authSvc.getPermisosWeb("WEB-ENCUESTAS").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosPoll.r = data.r;
				this.permisosPoll.w = data.w;
				this.permisosPoll.d = data.d;
			}
		});
		this.userSvc.getUser().subscribe((data: User) => {
			if (data["onBoardingOK"] == 0 && this.projectId == 472) {
				this.router.navigate(["onboarding"]);
			}
			this.user = data;
			if (this.projectId == this.TANDEM_PROJECT_ID) {
				this.evaluationSvc.getRegistroTandem(this.user.id).subscribe((data) => {
					if (data.canDo) {
						this.canDo = data.canDo;
						const currentTime = moment();
						const is13ormore = currentTime.hour() >= 13;
						if (is13ormore) this.remindDailyEvaluation();
					}
				});
				//Cargamos datos de VD
				this.evaluationSvc.getEvaluationPeriods("EVALUATION_VH").subscribe((data: any) => {
					this.evaluationPeriod = data[0];
					if (this.evaluationPeriod) {
						this.evaluationSvc.getEvaluationVHByUser(this.evaluationPeriod.id, this.user.id, this.user.puestos[0].id).subscribe((data: any) => {
							this.evaluation = data;
							console.log("getEvaluationVHByUser", this.evaluation);
						});
					}
				});
			}
		});

		this.userSvc.profile().subscribe((data: any) => {
			this.profileUser = data;

			if (data.delegacion) {
				this.badgesSvc.getMedallasDelegacion(data.delegacion.id).subscribe((data) => {
					this.users = data;
					this.usersRecibidas = Array.from(this.users).sort((a, b) => b.recibidas - a.recibidas);
					this.usersEntregadas = Array.from(this.users).sort((a, b) => b.entregadas - a.entregadas);
				});
			}
		});

		this.projectSvc.getProjectConfig().subscribe(
			(data) => {
				this.project = data;
				this.projectId = this.project.id;
				if (this.projectId == 472) {
					this.loadRecentNewsFun();
				}
			},
			(err) => {},
		);

		this.badgesSvc.checkSemanales().subscribe((data) => {
			console.log("Semanales", data);
			if (data) {
				this.badgesTandem = data.length;
			}
		});
		this.loadTimeline(true);
		this.loadRecentNews();
		this.loadTopByCompetency();
		let darBadge = false;
		let badgeReturn = 0;
		this.route.queryParams.subscribe((params) => {
			darBadge = params["darBadge"];
			badgeReturn = params["badgeReturn"];
		});

		if (darBadge !== undefined && darBadge !== null) {
			this.openDialogBadge(badgeReturn);
		}
		let idarray = [];
		this.userSvc.getUser().subscribe((data) => {
			this.personSvc.getPerson(data.id).subscribe(async (data) => {
				localStorage.setItem("roles", JSON.stringify(data.roles));
			});
			this.personSvc.getListaTrabajadores(data.id).subscribe((data) => {
				for (let trab of data) {
					idarray.push(trab.id);
				}
				localStorage.setItem("bossOf", JSON.stringify(idarray));
			});
		});
	}

	loadTimeline(reload: boolean): void {
		this.badgesSvc.timeline(this.pageTimeline).subscribe((data: any) => {
			this.processDataTimeline(data, reload);
		});
	}

	loadRecentNews(): void {
		if (this.projectSvc.projectConfig.id == 472) {
			this.postsSvc.recentNews(this.pageRecentNews, { funespana_especial: 0 }).subscribe((data: any) => {
				this.processDataRecentNews(data);
			});
		} else {
			this.postsSvc.recentNews(this.pageRecentNews).subscribe((data: any) => {
				this.processDataRecentNews(data);
			});
		}
	}

	loadRecentNewsFun(): void {
		this.postsSvc.recentNews(this.pageRecentNewsFun, { funespana_especial: 1 }).subscribe((data: any) => {
			this.processDataRecentNewsFun(data);
		});
	}

	private processDataTimeline(data: any, reload: boolean) {
		if (reload) {
			this.badgesTimeline = [];
			this.idsBadges = [];
		}
		this.hasMoreTimeline = data.hasMore;

		for (let itemBadge of data.elements) {
			if (this.idsBadges.includes(itemBadge.id)) {
				for (let badge of this.badgesTimeline) {
					if (badge.id == itemBadge.id) {
						if (badge["competencyIndicator"]) {
							badge["competencyIndicator"] = badge["competencyIndicator"].concat(itemBadge.competencyIndicator);
						}
					}
				}
			} else {
				this.badgesTimeline.push(<Badge>itemBadge);
				this.idsBadges.push(itemBadge.id);
			}
		}
		this.badgesTimelineLoaded = true;
	}

	private processDataRecentNews(data: any) {
		this.hasMoreRecentNews = data.hasMore;
		for (let post of data.elements) {
			if (!post["title_prefix"].includes("${ranking.month}") || (this.project.enabled_rankings && this.project.entryPoint["showRankingPost"])) {
				this.recentNews.push(<Post>post);
			}
		}
		this.recentNewsLoaded = true;
	}

	onSrollTimeline() {
		if (this.hasMoreTimeline) {
			this.pageTimeline++;
			this.loadTimeline(false);
		}
	}
	onSrollRecentNews() {
		if (this.hasMoreRecentNews) {
			this.pageRecentNews++;
			this.loadRecentNews();
		}
	}

	private processDataRecentNewsFun(data: any) {
		this.hasMoreRecentNewsFun = data.hasMore;
		for (let post of data.elements) {
			if (!post["title_prefix"].includes("${ranking.month}") || (this.project.enabled_rankings && this.project.entryPoint["showRankingPost"])) {
				this.recentNewsFun.push(<Post>post);
			}
		}
		this.recentNewsLoadedFun = true;
	}
	onSrollRecentNewsFun() {
		if (this.hasMoreRecentNewsFun) {
			this.pageRecentNewsFun++;
			this.loadRecentNewsFun();
		}
	}

	openDialogClima(valueClima): void {
		if (this.profileUser.climaToday === 0) {
			if (this.projectId == 10) {
				this.userSvc.saveClima(valueClima, "").subscribe((data) => {
					if (data && data !== undefined) {
						this.profileUser.climaToday = Number(valueClima);
					}
				});
			} else {
				const dialogRef = this.dialog.open(DialogClimaComponent, {
					data: { value: valueClima, mandatoryReason: this.project.mandatoryReasonClima ? true : false },
					panelClass: "panel-feedbalia",
				});
				dialogRef.updatePosition({ top: "2rem" });

				dialogRef.afterClosed().subscribe((result) => {
					if (result && result !== undefined) {
						this.profileUser.climaToday = result;
					}
				});
			}
		}
	}

	loadTopByCompetency(year = "", month = "", type = "grantee") {
		switch (type) {
			case "grantor":
				this.rankSvc.topCompetencyGrantor(year, month).subscribe((data: any) => {
					this.topByCompetency = data;

					if (data[0]) {
						this.selectedCompetencia = data[0];
					}

					if (this.topByCompetency[0] && this.topByCompetency[0].topFive[0]) {
						this.maxPointCompetenciAux = this.topByCompetency[0].topFive[0].count;
					} else {
						this.maxPointCompetenciAux = 0;
					}

					this.competenciesLoaded = true;
				});
				break;
			default:
				this.rankSvc.topCompetencyGrantee(this.yearIndex.toString(), this.monthIndex.toString()).subscribe((data: any) => {
					this.topByCompetency = data;

					if (data[0]) {
						this.selectedCompetencia = data[0];
					}

					if (this.topByCompetency[0] && this.topByCompetency[0].topFive[0]) {
						this.maxPointCompetenciAux = this.topByCompetency[0].topFive[0].count;
					} else {
						this.maxPointCompetenciAux = 0;
					}

					this.competenciesLoaded = true;
				});
				break;
		}
	}

	private openDialogBadge(badgeReturn) {
		const dialogRef = this.dialog.open(DialogDarBadgeComponent, {
			panelClass: ["panel-feedbalia", "dar-badge"],
			width: window.screen.width > 768 ? "50vw" : "100vw",
			disableClose: true,
			autoFocus: false,
			data: { badgeReturn: badgeReturn },
		});
		dialogRef.updatePosition({ top: "2rem" });
	}

	nextSection() {
		var ind = this.topByCompetency.indexOf(this.selectedCompetencia);
		if (ind != this.topByCompetency.length - 1) {
			this.selectedCompetencia = this.topByCompetency[this.topByCompetency.indexOf(this.selectedCompetencia) + 1];
		} else {
			this.selectedCompetencia = this.topByCompetency[0];
		}
	}

	previousSection() {
		var ind = this.topByCompetency.indexOf(this.selectedCompetencia);
		if (ind != 0) {
			this.selectedCompetencia = this.topByCompetency[this.topByCompetency.indexOf(this.selectedCompetencia) - 1];
		} else {
			this.selectedCompetencia = this.topByCompetency[this.topByCompetency.length - 1];
		}
	}

	rankBg(index) {
		switch (Number(index)) {
			case 1:
				return "gold-bg";
			case 2:
				return "silver-bg";
			case 3:
				return "bronze-bg";
			default:
				return "none-bg";
		}
	}

	getImagePerson(username: string) {
		return this.imagesSvc.getImagePerson(username, this.projectId);
	}

	calcHeightTop(total: number) {
		let allValues = 0;
		for (let elem of this.selectedCompetencia.topFive) {
			allValues += Number(elem.count);
		}
		var height: number = 100;
		height = (total * 100) / allValues;
		height = height + 20;
		if (height > 100) {
			height = 100;
		}

		return height + "%";
	}

	convertirMes(numeroMes) {
		var meses = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

		if (numeroMes >= 1 && numeroMes <= 12) {
			return meses[numeroMes - 1];
		}
	}

	ngOnDestroy(): void {
		this.updateBadgeSubscription.unsubscribe();
	}

	goToSied() {
		this.router.navigate(["/sied"]);
	}

	goToPoll() {
		this.router.navigate(["/poll"]);
	}

	remindDailyEvaluation() {
		setTimeout(() => {
			const driverObj = driver({
				animate: true,
				showButtons: [null],
				steps: [
					{
						element: "#registro-diario",
						popover: {
							title: "Recordatorio de registro diario",
							description: "¡Recuerda que debes realizar tu registro diario!",
						},
					},
				],
			});

			driverObj.drive();
			window.addEventListener("click", () => {
				driverObj.destroy();
			});
		}, 4000);
	}

	goToRegistroDiario() {
		this.router.navigate(["/vuelta-d"]);
	}

	formatDate(date: string) {
		if (!date) {
			return "";
		}
		let _date = moment(date).format("DD/MM/YYYY HH:mm");
		return _date;
	}

	loadAutoEvaluation(evaluation, isNew?: boolean) {
		const _params = {
			formId: evaluation.formId,
			personId: this.user.id,
			periodId: this.evaluationPeriod.id,
			iterationNumber: isNew ? Number(evaluation.iterationNumber) + 1 : evaluation.iterationNumber,
		};
		this.router.navigate(["/vuelta-d/form"], { queryParams: _params });
	}
	checkAlumn() {
		if (this.user) {
			if (this.user.puestos) {
				if (this.user.puestos.length > 0) {
					if (this.user.puestos[0].puesto.includes("ALUMN")) {
						return true;
					}
				}
			}
		}
		return false;
	}
}
