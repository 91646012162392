import { Component, OnDestroy, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import _ from "lodash";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { FuseSidebarService } from "@fuse/components/sidebar/sidebar.service";
import { FuseConfigService } from "@fuse/services/config.service";

import { MatSnackBar } from "@angular/material/snack-bar";

import { navigation } from "app/navigation/navigation";

import { AuthService } from "../../../services/auth/auth.service";
import { ImagesService } from "../../../services/helper/images.service";
import { PersonService } from "../../../services/person/person.service";
import { ProjectService } from "../../../services/project/project.service";
import { UserService } from "../../../services/user/user.service";

import { MatDialog } from "@angular/material/dialog";
import { FuseNavigation } from "@fuse/types";
import { DialogDarBadgeComponent } from "app/components/dialog-dar-badge/dialog-dar-badge.component";
import { DialogIncidenciaComponent } from "app/components/dialog-incidencia/dialog-incidencia.component";
import { EvaluationService } from "app/services/evaluation/evaluation.service";
import { NotificationService } from "app/services/notifications/notification.service";
import { VacacionesService } from "app/services/vacaciones/vacaciones.service";
import { Project } from "app/shared/models/project.interface";
import { Persona, User } from "app/shared/models/user.interface";
import moment from "moment";
import { DialogReadOnlyComponent } from "./dialog-readOnly/dialog-readOnly.component";

@Component({
	selector: "toolbar",
	templateUrl: "./toolbar.component.html",
	styleUrls: ["./toolbar.component.scss"],
	encapsulation: ViewEncapsulation.None,
})
export class ToolbarComponent implements OnInit, OnDestroy {
	public entorno: string = "WEB";

	horizontalNavbar: boolean;
	rightNavbar: boolean;
	hiddenNavbar: boolean;
	languages: any;
	navigation: FuseNavigation[];
	selectedLanguage: any;
	userStatusOptions: any[];
	medalsShortcutToolbarEnabled: boolean;

	person: Persona;
	projectId: number;
	projectData: any;
	persons: Array<Persona>;
	urlAdmin: string = "";

	notifications: any;
	notificationsFormaciones: any;
	readOnlyMode: boolean = false;

	hasMoreFormaciones: boolean = false;
	pageFormaciones: number = 0;

	dominio = "";
	canEnterNewAdmin = false;
	newAdminPermisos = false;

	// Private
	private _unsubscribeAll: Subject<any>;

	/**
	 * Constructor
	 *
	 * @param {FuseConfigService} _fuseConfigService
	 * @param {FuseSidebarService} _fuseSidebarService
	 * @param {TranslateService} _translateService
	 */
	constructor(
		private _fuseConfigService: FuseConfigService,
		private _fuseSidebarService: FuseSidebarService,
		private _translateService: TranslateService,
		private snackBar: MatSnackBar,
		private router: Router,
		private authSvc: AuthService,
		private projectSvc: ProjectService,
		private imagesSvc: ImagesService,
		private personSvc: PersonService,
		private notificationSvc: NotificationService,
		private evaluationSvc: EvaluationService,
		private userSvc: UserService,
		private dialog: MatDialog,
		private vacSvc: VacacionesService,
	) {
		// Set the defaults
		this.userStatusOptions = [
			{
				title: "Online",
				icon: "icon-checkbox-marked-circle",
				color: "#4CAF50",
			},
			{
				title: "Away",
				icon: "icon-clock",
				color: "#FFC107",
			},
			{
				title: "Do not Disturb",
				icon: "icon-minus-circle",
				color: "#F44336",
			},
			{
				title: "Invisible",
				icon: "icon-checkbox-blank-circle-outline",
				color: "#BDBDBD",
			},
			{
				title: "Offline",
				icon: "icon-checkbox-blank-circle-outline",
				color: "#616161",
			},
		];

		this.languages = [];

		this.navigation = navigation;
		this.persons = [];

		// Set the private defaults
		this._unsubscribeAll = new Subject();

		this.router.routeReuseStrategy.shouldReuseRoute = () => false;

		this.authSvc.entorno.subscribe((entorno: string) => {
			if (this.entorno != entorno) {
				this.entorno = entorno;
			}
		});
	}

	permisosConfig = { r: 0, w: 0, d: 0 };
	permisosEV = { r: 0, w: 0, d: 0 };

	ngOnInit() {
		this.authSvc.getPermisosWeb("WEB-CONFIGURACION").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosConfig.r = data.r;
				this.permisosConfig.w = data.w;
				this.permisosConfig.d = data.d;
			}
		});

		this.authSvc.getPermisosWeb("WEB-EVALUACIONES").subscribe((data) => {
			console.log("Permisos para esta pagina", data);
			if (data) {
				this.permisosEV.r = data.r;
				this.permisosEV.w = data.w;
				this.permisosEV.d = data.d;
			}
		});
		// Subscribe to the config changes
		this._fuseConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((settings) => {
			this.horizontalNavbar = settings.layout.navbar.position === "top";
			this.rightNavbar = settings.layout.navbar.position === "right";
			this.hiddenNavbar = settings.layout.navbar.hidden === true;
		});

		this.projectSvc.getProjectConfig().subscribe((dataProject: Project) => {
			this.projectId = dataProject.id;
			this.projectData = dataProject;
			this.getLanguages(dataProject);
			this.getUrlAdmin();
			this.getNotifications();
			this.getNotificationsFormaciones();
			this.medalsShortcutToolbarEnabled = dataProject.medalsShortcutToolbarEnabled;
			this.canEnterNewAdmin = dataProject.newAdmin;
			this.authSvc.getPermisosAdmin().subscribe((data) => {
				if (data) {
					if (data.permisos) this.newAdminPermisos = true;
				}
			});
		});
		this.userSvc.getDataUser().subscribe((user) => {
			this.readOnlyMode = user.readOnlyMode;
			this.personSvc.getPerson(user.id).subscribe((person) => {
				this.person = person;
			});
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		// Unsubscribe from all subscriptions
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}

	getLanguages(dataProject) {
		const supportedLanguages = dataProject.supportedLanguages;
		const arrayLang = supportedLanguages.split(",");
		for (const lang of arrayLang) {
			let objLang = {
				id: lang,
				title: this._translateService.instant("language." + lang),
				flag: lang,
			};
			this.languages.push(objLang);
		}
		this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
		this.userSvc.getDataUser().subscribe((user) => {
			this.personSvc.getPerson(user.id).subscribe((person) => {
				this._translateService.use(this.person?.language ? this.person.language : "es");
				localStorage.setItem("currentLang", this.person?.language ? this.person.language : "es");

				const idioma = this.person
					? this.languages.filter((lang) => lang.id === this.person.language)
					: this.languages.filter((lang) => lang.id === "es");

				if (idioma.length != 0) {
					this.selectedLanguage = idioma[0];
				}
			});
		});
	}

	getNotifications() {
		this.notificationSvc.getNotificaciones(0, 20, 0).subscribe((data) => {
			this.notifications = data;
			// Comprobar evaluaciones pendientes
			const _now = moment().format("YYYY-MM-DD");
			this.evaluationSvc.getMainPeriod(_now).subscribe((period) => {
				if (period) {
					this.evaluationSvc.getEvaluationsPendings(period.id).subscribe((data) => {
						if (data && data.totalPendings && this.permisosEV.r) {
							const _evaluationPendings = data.totalPendings;
							this.notifications.elements.unshift({
								modelName: "Evaluation",
								title: `${_evaluationPendings} evaluaciones pendientes`,
								message: `Tienes ${_evaluationPendings} evaluaciones pendientes`,
							});
							this.notifications.totalElements = Number(this.notifications.totalElements) + 1;
						}
					});
				}
			});

			//Creamos las notificaciones de vacaciones pendientes
			if (this.projectData.vacation_allowed_web && this.projectData.vacation_allowed) {
				this.vacSvc.getAllSolicitudesPendientes().subscribe((data) => {
					const _solPending = data.total;
					if (_solPending) {
						this.notifications.elements.unshift({
							modelName: "VacationPending",
							title: `${_solPending} `,
							message: `Tienes ${_solPending} solicitudes de vacaciones de tu equipo pendientes`,
						});
						this.notifications.totalElements = Number(this.notifications.totalElements) + 1;
					}
				});
			}
		});
	}
	getNotificationsFormaciones() {
		if (this.projectId == 10) {
			this.notificationSvc.getNotificaciones(0, 10, null, "Formaciones").subscribe((data) => {
				console.log("notificaciones Formaciones", data);
				this.notificationsFormaciones = data;
				this.hasMoreFormaciones = data.hasMore;
			});
		}
	}

	openNotification(notification) {
		// console.log("abrimos noti",notification)
		const _modelName = notification.modelName;
		if (_modelName.includes("Badge")) {
			this.router.navigate(["/perfil/"]);
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("PrivateFeedback")) {
			this.router.navigate(["/sied/"]);
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Evaluation")) {
			this.router.navigate(["/sied/"]);
		}
		if (_modelName.includes("disablePda")) {
			const queryParams = { person: Number(notification.modelId) };
			this.router.navigate(["/sied"], { queryParams });
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Formaciones-Accion")) {
			this.router.navigate(["/formaciones-menu/"], { queryParams: { showFormaciones: "4" } });
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Formaciones-Externa")) {
			this.router.navigate(["/formaciones-menu/"], { queryParams: { showFormaciones: "4" } });
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Formaciones-Grupo")) {
			this.router.navigate(["/formaciones-menu/"], { queryParams: { showFormaciones: "1" } });
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Formaciones-Convocatoria")) {
			this.markAsReaded(notification.id);
		}

		if (_modelName.includes("Formaciones-Bienestar")) {
			this.router.navigate(["/formaciones-solicitudes/"]);
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("Formaciones-AvisoUsuario")) {
			this.router.navigate(["/formaciones-menu/"], { queryParams: { showFormaciones: "2" } });
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("InfoPost")) {
			this.router.navigate(["/publicaciones/"]);
			this.markAsReaded(notification.id);
		}
		if (_modelName.includes("VacationPending")) {
			this.router.navigate(["/vacaciones/team"]);
			//this.markAsReaded(notification.id);
		}
		if (_modelName.includes("VacacionesSol")) {
			this.router.navigate(["/vacaciones/user"]);
			this.markAsReaded(notification.id);
		}
	}

	markAsReaded(id) {
		this.notificationSvc.markNotificationAsRead(id).subscribe((data) => {
			if (data.success) {
				this.getNotifications();
				this.getNotificationsFormaciones();
			}
		});
	}

	markAllAsReaded() {
		this.notificationSvc.markAllNotificationsAsRead().subscribe((data) => {
			if (data.success) {
				this.getNotifications();
				this.getNotificationsFormaciones();
			}
		});
	}

	markAllAsReadedFormaciones() {
		this.notificationSvc.markAllNotificationsAsReadFormaciones().subscribe((data) => {
			if (data.success) {
				this.getNotifications();
				this.getNotificationsFormaciones();
			}
		});
	}

	toggleSidebarOpen(key): void {
		this._fuseSidebarService.getSidebar(key).toggleOpen();
	}

	search(value): void {
		// Do your search here...
		if (value.length > 2) {
			this.getPersons(value);
		} else {
			this.persons = [];
		}
	}

	closeSearch() {
		this.persons = [];
	}

	getPersons(filterPersons) {
		this.personSvc.getPersons(filterPersons, 0, 5).subscribe(
			(personsData) => {
				this.persons = personsData.elements;
			},
			(err) => {
				this.snackBar.open(err, "X", {
					duration: 3000,
				});
			},
		);
	}

	selectPerson(user: Persona) {
		this.persons = [];
		this.personSvc.searchClose.next(null);
		this.router.navigate(["/perfil/@" + user.username]);
	}

	getImagePerson(username: string) {
		return this.imagesSvc.getImagePerson(username, this.projectId);
	}

	setLanguage(lang): void {
		// Set the selected language for the toolbar
		this.selectedLanguage = lang;

		// Use the selected language for translations
		this._translateService.use(lang.id);
		localStorage.setItem("currentLang", lang.id);
	}

	cerrarSesion() {
		if (this.authSvc.hasOldToken()) {
			const oldToken = localStorage.getItem("oldToken");
			localStorage.setItem("tokenUser", oldToken);
			localStorage.removeItem("oldToken");
			location.reload();
		} else {
			this.authSvc.logout();
			if (this.projectSvc.projectConfig.enabled_cas_login) {
				window.location.href = "saml/logout";
			} else {
				this.router.navigate(["login"], { replaceUrl: true, queryParams: { returnUrl: this.router.url } });
			}
		}
	}

	getImageUser(user: User) {
		return this.imagesSvc.getImageThumb(user.username);
	}

	getUrlAdmin() {
		this.projectSvc.getUrlAdmin().subscribe((data: any) => {
			if (data && data.url) {
				this.urlAdmin = data.url;
			}
		});
	}

	navigateTo(externalLink) {
		window.location.href = externalLink;
	}

	openReadOnlyModal() {
		const dialogRef = this.dialog.open(DialogReadOnlyComponent, {
			panelClass: ["panel-feedbalia"],
			width: window.screen.width > 768 ? "50vw" : "100vw",
			autoFocus: false,
			data: {},
		});
		dialogRef.updatePosition({ top: "2rem" });
	}

	async goToAnalytics() {
		let roles = this.person.roles;

		let then = true;
		if (roles[0].rol == "ROLE_ADMIN_APP") {
			then = false;
			this.router.navigate(["/dashboard/admin"]);
		}

		if (roles[0].rol == "ADMIN_DELEGACION") {
			then = false;

			this.router.navigate(["/dashboard/delegacion"]);
		}

		if (JSON.parse(localStorage.getItem("bossOf")).length != 0 && then) {
			this.router.navigate(["/dashboard/admin-boss"]);
		}
	}

	//TODO No funciona evento scroll ¿¿??
	onSrollNotifications(evt) {}

	onSrollNotificationsFormaciones() {
		if (this.hasMoreFormaciones) {
			this.pageFormaciones++;
			this.notificationSvc.getNotificaciones(this.pageFormaciones, 10, null, "Formaciones").subscribe((data) => {
				console.log("Adicionales:", data);
				for (let elem of data.elements) {
					this.notificationsFormaciones.elements.push(elem);
				}
				this.hasMoreFormaciones = data.hasMore;
			});
		}
	}

	getNotificationIcon(type) {
		switch (type) {
			case "Badge": {
				return "favorite_border";
			}
			case "BadgeLike": {
				return "thumb_up_off_alt";
			}
			case "BadgeComment": {
				return "chat_bubble_outline";
			}
			case "RecentNews": {
				return "public";
			}
			case "InfoPost": {
				return "public";
			}
			case "PrivateFeedbackRequest": {
				return "feedback";
			}
			case "PrivateFeedbackResponse": {
				return "feedback";
			}
			case "Evaluation": {
				return "assignment_turned_in";
			}
			case "disablePda": {
				return "not_interested";
			}
			case "Formaciones-Accion": {
				return "school";
			}
			case "Formaciones-Externa": {
				return "school";
			}
			case "Formaciones-Convocatoria": {
				return "email";
			}
			case "Formaciones-Grupo": {
				return "school";
			}
			case "Formaciones-Bienestar": {
				return "school";
			}
			case "Formaciones-AvisoUsuario": {
				return "school";
			}
			case "VacationPending": {
				return "assignment_turned_in";
			}
			case "VacacionesSol": {
				return "flight_takeoff";
			}
		}
	}

	openHelpDialog() {
		const dialogRef = this.dialog.open(DialogIncidenciaComponent, {
			panelClass: "panel-feedbalia",
		});
		dialogRef.updatePosition({ top: "2rem" });
	}

	darBadgeOpenDialog() {
		const dialogRef = this.dialog.open(DialogDarBadgeComponent, {
			panelClass: ["panel-feedbalia"],
			width: window.screen.width > 768 ? "50vw" : "100vw",
			disableClose: true,
			autoFocus: false,
		});
		dialogRef.updatePosition({ top: "2rem" });
	}
}
