<div id="inicio" class="page-layout simple fullwidth" [ngClass]="projectId == 453 ? 'background-syrsa' : ''">
	<div fxLayout="row wrap">
		<!-- IZQUIERDA -->

		<div fxLayout="column" fxFlex="1 1 0" class="px-16">
			<div @onRenderUp fxLayout="row wrap" class="bienvenida">
				<div class="saludo">
					<h3 *ngIf="user" class="mat-headline-5" [ngClass]="projectId == 453 ? 'hello-syrsa' : ''">
						{{ "index.clima.label" | translate }} {{ user.firstName }}!
						@if (permisosClima.w) {
							<small *ngIf="project?.entryPoint.clima_enabled">{{ "index.clima.question" | translate }}</small>
						}
					</h3>
				</div>
				@if (permisosClima.w) {
					<div
						*ngIf="project?.entryPoint.clima_enabled"
						class="clima-container"
						[class]="profileUser && profileUser.climaToday !== 0 ? 'active' : ''"
						fxLayout="row"
					>
						<a
							class="clima-icon"
							[class]="profileUser && profileUser.climaToday === 2 ? 'selected' : ''"
							fxLayout="row"
							fxLayoutAlign="center center"
							[ngClass]="{ colorverygood: project.showClimaTooltip }"
							(click)="openDialogClima(2)"
							[matTooltip]="project.showClimaTooltip ? 'Alegre' : ''"
						>
							<mat-icon>sentiment_very_satisfied</mat-icon>
						</a>
						<a
							class="clima-icon"
							[class]="profileUser && profileUser.climaToday === 1 ? 'selected' : ''"
							fxLayout="row"
							fxLayoutAlign="center center"
							(click)="openDialogClima(1)"
							[ngClass]="{ colorgood: project.showClimaTooltip }"
							[matTooltip]="project.showClimaTooltip ? 'Entusiasmado' : ''"
						>
							<mat-icon>sentiment_satisfied</mat-icon>
						</a>
						<a
							class="clima-icon"
							[class]="profileUser && profileUser.climaToday === -1 ? 'selected' : ''"
							fxLayout="row"
							fxLayoutAlign="center center"
							[ngClass]="{ colorbad: project.showClimaTooltip }"
							(click)="openDialogClima(-1)"
							[matTooltip]="project.showClimaTooltip ? 'Triste' : ''"
						>
							<mat-icon>sentiment_dissatisfied</mat-icon>
						</a>
						<a
							class="clima-icon"
							[class]="profileUser && profileUser.climaToday === -2 ? 'selected' : ''"
							fxLayout="row"
							fxLayoutAlign="center center"
							(click)="openDialogClima(-2)"
							[ngClass]="{ colorverybad: project.showClimaTooltip }"
							[matTooltip]="project.showClimaTooltip ? 'Enfadado' : ''"
						>
							<mat-icon>sentiment_very_dissatisfied</mat-icon>
						</a>
					</div>
				}
				@if (projectId == 473) {
					@if (checkAlumn()) {
						<div>
							<h2 style="margin-left: 12px; width: 100%; text-align: right">
								Medallas semanales: <b>{{ badgesTandem }}/3</b>
							</h2>
						</div>
					}
				}
			</div>

			@if (permisosMedallas.r || permisosTienda.r) {
				<div
					*ngIf="project?.entryPoint.enabled_badges && projectId != TANDEM_PROJECT_ID"
					@onRenderBottom
					fxLayout="row wrap"
					class="badges-box mt-16"
				>
					@if (permisosMedallas.r) {
						<badges-mes style="margin-top: 10px" class="mr-16" tipo="RECIBIDO" [num_badges]="profileUser.totalNumBadgeReceived"></badges-mes>
						<badges-mes style="margin-top: 10px" class="mr-16" tipo="ENTREGADO" [num_badges]="profileUser.totalNumBadgeSended"></badges-mes>
						<badges-mes
							style="margin-top: 10px"
							*ngIf="projectId == 288"
							class="mr-16"
							tipo="RECIBIDOMEJORA"
							[num_badges]="profileUser.NumBadgeMejorasRecived"
						></badges-mes>
						<badges-mes
							style="margin-top: 10px"
							*ngIf="projectId == 288"
							class="mr-16"
							tipo="ENTREGADOMEJORA"
							[num_badges]="profileUser.NumBadgeMejorasSended"
						></badges-mes>
					}
					@if (permisosTienda.r) {
						<badges-mes
							style="margin-top: 10px"
							*ngIf="project && project.enabledTransactions"
							tipo="MONEDAS"
							[num_badges]="profileUser.totalMonedas"
						></badges-mes>
					}
				</div>
			}

			<div *ngIf="projectId == TANDEM_PROJECT_ID" @onRenderBottom fxLayout="row wrap" class="badges-box mt-16" style="width: 100%">
				<div *ngIf="evaluation && evaluation.formId" class="fuse-card card-border-top card-vuelta">
					<div fxLayout="row">
						<div fxLayout="column" fxLayoutAlign="center center" class="user-container">
							<div class="person-avatar" fxLayout="row" fxLayoutAlign="center flex-start">
								<img class="img-circle" [src]="getImagePerson(user && user.username)" [alt]="user && user.username" />
							</div>
							<h5 class="text-center">{{ user && user.displayName }}</h5>
							<h5 class="text-center">{{ user && user.puestos.length && user.puestos[0].puesto }}</h5>
						</div>

						<div fxLayout="column" class="data-container">
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span class="mr-8">Registros enviados este año:</span>
								<span class="vueltas">{{ evaluation && evaluation.iterationNumber }}</span>
							</div>
							<div class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<span class="mr-8">Últ. registro:</span>
								<span>{{ evaluation && formatDate(evaluation.updateDate) }}</span>
							</div>
							<div *ngIf="evaluation && evaluation.iterationNumber == 0 && canDo" class="m-4" fxLayout="row" fxLayoutAlign="flex-start center">
								<button mat-raised-button class="btn-feedbalia btn-vuelta-d" (click)="loadAutoEvaluation(evaluation, true)" id="registro-diario">
									Nuevo Registro Diario
								</button>
							</div>
							<div
								*ngIf="evaluation && evaluation.iterationNumber > 0 && evaluation.evaluationStatus == 'DONE'"
								class="m-4"
								fxLayout="row"
								fxLayoutAlign="flex-start center"
							>
								<button mat-raised-button class="btn-feedbalia btn-vuelta-d" (click)="loadAutoEvaluation(evaluation, false)">
									Ver Último Registro
								</button>
							</div>
							<div
								*ngIf="evaluation && evaluation.iterationNumber > 0 && evaluation.evaluationStatus == 'DONE'"
								class="m-4"
								fxLayout="row"
								fxLayoutAlign="flex-start center"
							>
								<button
									mat-raised-button
									class="btn-feedbalia btn-vuelta-d"
									(click)="loadAutoEvaluation(evaluation, true)"
									*ngIf="canDo"
									id="registro-diario"
								>
									Nuevo Registro Diario
								</button>
								<button mat-raised-button class="btn-feedbalia disabled-button" *ngIf="!canDo" disabled>Ya has envidado tu registro hoy</button>
							</div>
						</div>
					</div>
				</div>
			</div>

			@if (permisosPublicaciones.r) {
				<div
					*ngIf="project?.entryPoint.posts_enabled && project?.entryPoint.show_publication_in_home"
					@onRenderBottom
					fxLayout="column"
					class="publicaciones mt-16"
					fxLayoutAlign="center center"
				>
					<h4 class="font-size-18 m-0 mb-4">
						<a *ngIf="projectId != TANDEM_PROJECT_ID">{{ "dashboard.tabs.posts" | translate }}</a>
						<a *ngIf="projectId == TANDEM_PROJECT_ID">Foro de discusión</a>
					</h4>

					<div
						class="fuse-card card-border-top p-16"
						infiniteScroll
						[infiniteScrollDistance]="1"
						[infiniteScrollThrottle]="50"
						[scrollWindow]="false"
						(scrolled)="onSrollRecentNews()"
						[ngClass]="{ 'lista-syrsa': projectId == 453, 'list-publicaciones': projectId != 453 }"
					>
						<div *ngIf="!recentNewsLoaded" fxLayout="row" fxLayoutAlign="center" class="spinner">
							<mat-spinner color="accent"></mat-spinner>
						</div>
						<div *ngIf="recentNewsLoaded" @slideInLeftList>
							<div *ngFor="let itemPost of recentNews">
								@if (project.newPostPublicaciones == 1) {
									<item-post-pro
										[user]="user"
										[id]="itemPost.id"
										[datos]="itemPost"
										[canSee]="permisosPublicacionesCL.r"
										[canEdit]="permisosPublicacionesCL.w"
									></item-post-pro>
								} @else {
									<item-post
										[user]="user"
										[id]="itemPost.id"
										[datos]="itemPost"
										[canSee]="permisosPublicacionesCL.r"
										[canEdit]="permisosPublicacionesCL.w"
									></item-post>
								}
							</div>
						</div>
					</div>
				</div>
			}
		</div>

		<!-- DERECHA -->
		@if (permisosMedallas.r) {
			<div
				*ngIf="projectId != TANDEM_PROJECT_ID && project?.entryPoint.showHomeTimeline && project.entryPoint.enabled_badges"
				@onRenderBottom
				fxLayout="column"
				fxFlex="1 1 0"
			>
				<div fxLayout="column" class="agradecimientos mt-16 w-100-p" fxLayoutAlign="flex-start center" fxFlex="1 1 0">
					<h4 class="font-size-18 m-0 mb-4">
						<a>{{ "ranking.badges" | translate }}</a>
					</h4>

					<div
						class="fuse-card list-agradecimientos card-border-top"
						infiniteScroll
						[infiniteScrollDistance]="1"
						[infiniteScrollThrottle]="50"
						[scrollWindow]="false"
						(scrolled)="onSrollTimeline()"
					>
						<div *ngIf="!badgesTimelineLoaded" fxLayout="row" fxLayoutAlign="center" class="spinner">
							<mat-spinner color="accent"></mat-spinner>
						</div>
						<div *ngIf="badgesTimelineLoaded" @slideInRightList>
							<div *ngFor="let itemBadge of badgesTimeline">
								<item-badge
									[user]="user"
									[id_badge]="itemBadge.id"
									[datos]="itemBadge"
									[canSee]="permisosMedallasCL.r"
									[canEdit]="permisosMedallasCL.w"
								></item-badge>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		<div
			*ngIf="
				projectId == 453 && !(project?.entryPoint.showHomeTimeline && project.entryPoint.enabled_badges) && !project?.entryPoint.showHomeHighlights
			"
			@onRenderBottom
			fxLayout="column"
			fxFlex="1 1 0"
		></div>
		@if (permisosPublicaciones.r) {
			<!-- TANDEM -->
			<div *ngIf="projectId == TANDEM_PROJECT_ID" @onRenderBottom fxLayout="column" fxLayoutAlign="start center" fxFlex="1 1 0">
				<h4 class="font-size-18 m-0 mb-4">
					<a>Ranking de medallas de {{ fechaActual | date: "MMMM" }} de {{ fechaActual | date: "yyyy" }}</a>
				</h4>

				<div fxLayout="column" fxLayoutAlign="start center" class="fuse-card list-agradecimientos card-border-top">
					<div *ngIf="!badgesTimelineLoaded" fxLayout="row" fxLayoutAlign="center" class="spinner">
						<mat-spinner color="accent"></mat-spinner>
					</div>

					<div *ngIf="badgesTimelineLoaded" fxLayout="row" class="list-agradecimientos gap-1" style="position: relative">
						<div fxLayout="column" fxLayoutAlign="start center" class="w-100-p gap-1">
							<h4 class="font-size-18 m-0 mt-8">
								<a>Recibidas</a>
							</h4>

							<div fxLayout="column" class="gap-1">
								<div *ngFor="let user of usersRecibidas" fxLayout="row" fxLayoutAlign="start center" class="gap-1">
									<img class="img-circle" [src]="getImagePerson(user?.username)" [title]="user?.username" />
									<div fxLayout="column">
										<label class="font-size-12">{{ user?.displayName }}</label>
										<div fxLayout="row" class="gap-1">
											<fa-icon
												[icon]="['fas', 'medal']"
												class="medal-icon"
												[style.color]="
													usersRecibidas.indexOf(user) === 0
														? 'gold'
														: usersRecibidas.indexOf(user) === 1
															? 'silver'
															: usersRecibidas.indexOf(user) === 2
																? 'brown'
																: 'black'
												"
											></fa-icon>
											{{ user.recibidas }}
										</div>
									</div>
								</div>
							</div>
						</div>

						<mat-divider vertical style="width: 1px; position: absolute; left: 50%; top: 5%; bottom: 5%; transform: translateX(-50%)"></mat-divider>

						<div fxLayout="column" fxLayoutAlign="start center" class="w-100-p gap-1">
							<h4 class="font-size-18 m-0 mt-8">
								<a>Entregadas</a>
							</h4>

							<div fxLayout="column" class="gap-1">
								<div *ngFor="let user of usersEntregadas" fxLayout="row" fxLayoutAlign="start center" class="gap-1">
									<img class="img-circle" [src]="getImagePerson(user?.username)" [title]="user?.username" />
									<div fxLayout="column">
										<label class="font-size-12">{{ user?.displayName }}</label>
										<div fxLayout="row" class="gap-1">
											<fa-icon
												[icon]="['fas', 'medal']"
												class="medal-icon"
												[style.color]="
													usersEntregadas.indexOf(user) === 0
														? 'gold'
														: usersEntregadas.indexOf(user) === 1
															? 'silver'
															: usersEntregadas.indexOf(user) === 2
																? 'brown'
																: 'black'
												"
											></fa-icon>
											{{ user.entregadas }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}
		<!-- FUNESPAÑA/ENALTA -->
		@if (permisosPublicaciones.r) {
			<div
				*ngIf="
					false &&
					projectId == 472 &&
					!(project?.entryPoint.showHomeTimeline && project.entryPoint.enabled_badges) &&
					!project?.entryPoint.showHomeHighlights
				"
				@onRenderBottom
				fxLayout="column"
				fxFlex="1 1 0"
				class="background-div"
			>
				<div fxLayout="column" class="agradecimientos mt-16 w-100-p" fxLayoutAlign="flex-start center" fxFlex="1 1 0">
					<h4 class="font-size-18 m-0 mb-4">
						<a>Enalta Sostenible</a>
					</h4>

					<div
						class="fuse-card list-publicaciones2 card-border-top p-16"
						infiniteScroll
						[infiniteScrollDistance]="1"
						[infiniteScrollThrottle]="50"
						[scrollWindow]="false"
						(scrolled)="onSrollRecentNewsFun()"
					>
						<div *ngIf="!recentNewsLoadedFun" fxLayout="row" fxLayoutAlign="center" class="spinner">
							<mat-spinner color="accent"></mat-spinner>
						</div>
						<div *ngIf="recentNewsLoadedFun" @slideInLeftList>
							<div *ngFor="let itemPost of recentNewsFun">
								<item-post
									[user]="user"
									[id]="itemPost.id"
									[datos]="itemPost"
									[canSee]="permisosPublicacionesCL.r"
									[canEdit]="permisosPublicacionesCL.w"
								></item-post>
							</div>
						</div>
					</div>
				</div>
			</div>
		}

		<!--Competencias LEROY-->
		@if (permisosMedallas.r) {
			<div *ngIf="project?.entryPoint.showHomeHighlights" @onRenderBottom fxLayout="column" fxFlex="1 1 0" class="px-16">
				<div fxLayout="column" class="publicaciones mt-16 w-100-p" fxLayoutAlign="space-around center" fxFlex="1 1 0">
					<div style="width: 100%">
						<h4 class="font-size-18 m-0 mb-4" fxLayoutAlign="center center">
							<a style="width: 100%; text-align: center">Destacados de {{ convertirMes(monthIndex) }}</a>
						</h4>
						<div class="fuse-card card-border-top competency" style="min-height: 300px">
							<div *ngIf="!competenciesLoaded" fxLayout="row" fxLayoutAlign="center">
								<mat-spinner color="accent"></mat-spinner>
							</div>
							<div *ngIf="competenciesLoaded" style="height: auto; margin-top: auto; margin-bottom: auto">
								<div @slideInRightList fxLayout="row" fxLayoutAlign="space-around start" class="competency-ranking-div">
									<div fxLayout="column" style="width: 80%">
										<div fxLayout="row">
											<div
												*ngIf="!selectedCompetencia.topFive.length > 0"
												fxLayout="column"
												fxLayoutAlign="center center"
												class="rank-table-div empty-div"
											>
												<label>{{ "index.ranking.not.available" | translate }}</label>
												<label>{{ "index.ranking.not.available2" | translate }} {{ "index.ranking.not.available3" | translate }}</label>
											</div>
										</div>
										<div fxLayout="row">
											<div *ngIf="selectedCompetencia.topFive.length > 0" fxLayout="column" class="rank-table-div">
												<div
													@slideInLeftListCompetencies
													*ngIf="selectedCompetencia.topFive"
													fxLayout="row-reverse"
													fxLayoutAlign="space-around none"
													class="rank-table"
												>
													<div *ngFor="let top of selectedCompetencia.topFive" fxLayout="column" fxLayoutAlign="center end" class="rank-column">
														<img [src]="getImagePerson(top.person_nickname)" [title]="top.person_nickname" class="img-circle automargin" />
														<div [style.height]="calcHeightTop(top.count)" [class]="rankBg(top.posicion)" class="rank-value fullwidth"></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div fxLayout="row" fxLayoutAlign="space-around start">
									<button (click)="previousSection()" mat-stroked-button color="primary">
										<mat-icon>arrow_back</mat-icon>
									</button>
									<p style="text-align: center">
										<b>{{ selectedCompetencia.competencia }}</b>
									</p>
									<button (click)="nextSection()" mat-stroked-button color="primary">
										<mat-icon>arrow_forward</mat-icon>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		}
	</div>
	<div fxLayout="row wrap" *ngIf="projectId == 94" style="margin-top: 20px">
		<!-- IZQUIERDA -->
		<div fxLayout="column" fxFlex="1 1 0" class="px-16" style="width: 100%">
			@if (permisosSIED.r) {
				<div @onRenderUp fxLayout="row" class="fuse-card card-border-top p-16" style="width: 100% !important; max-width: 100% !important">
					<div fxLayout="column" style="width: 100%" fxLayoutAlign="center center">
						<div fxLayout="row" fxLayoutAlign="center center">
							<h3 style="font-size: 130%">
								<b>{{ "inicio.bluespace.sied" | translate }}</b>
							</h3>
						</div>
						<div fxLayout="row" fxLayoutAlign="center center">
							<button
								mat-raised-button
								class="btn-feedbalia"
								style="width: 100%; font-size: 1.3em; padding: 8px; padding-left: 8px !important; padding-right: 8px !important"
								(click)="goToSied()"
							>
								{{ "inicio.bluespace.siedButton" | translate }}
							</button>
						</div>
					</div>
				</div>
			}
		</div>

		<!-- DERECHA -->
		<div fxLayout="column" fxFlex="1 1 0" class="px-16" style="width: 100%">
			@if (permisosPoll.r) {
				<div @onRenderUp fxLayout="row" class="fuse-card card-border-top p-16" style="width: 100% !important; max-width: 100% !important">
					<div fxLayout="column" style="width: 100%" fxLayoutAlign="center center">
						<div fxLayout="row" fxLayoutAlign="center center">
							<h3 style="font-size: 130%">
								<b>{{ "inicio.bluespace.poll" | translate }}</b>
							</h3>
						</div>
						<div fxLayout="row" fxLayoutAlign="center center">
							<button
								mat-raised-button
								class="btn-feedbalia"
								style="width: 100%; font-size: 1.3em; padding: 8px; padding-left: 8px !important; padding-right: 8px !important"
								(click)="goToPoll()"
							>
								{{ "inicio.bluespace.pollButton" | translate }}
							</button>
						</div>
					</div>
				</div>
			}
		</div>
	</div>
</div>
